<!--
 * @Description: 资质详情
 * @Author: huacong
 * @Date: 2021-06-10 16:52:23
 * @LastEditTime: 2021-09-02 09:51:46
 * @LastEditors: huacong
-->
<template>
  <div class="contentBox">
    <div class="infoBoxBg">
      <section>
        <el-row>
          <el-col :span="24" class="colorFFF header">
            <p class="text-center title">{{ partData.title }}</p>
            <p class="text-right time">
              <span> 发布时间：{{ partData.create_time }} </span>
            </p>
            <div class="line"></div>
            <p class="text-right bottom">
              <span>分类：{{ partData.category }}</span>
              <span>地区：{{ partData.province }}-{{ partData.city }}</span>
            </p>
          </el-col>
        </el-row>
      </section>
    </div>
    <section>
      <div>
        <h3 style="font-size:17px;">情况说明</h3>
        <p
          style="margin-bottom:50px;line-height: 1.7;"
          v-html="partData.content"
        ></p>
        <p>
          <span
            >联系人：<span class="font-blue">{{
              partData.contacts
            }}</span></span
          >
        </p>
        <p>
          <span>
            联系电话：<span class="font-blue">{{ partData.phone }}</span>
          </span>
          <span>（联系我时，请说是在建搜搜上看到的 ）</span>
        </p>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: "aptitudeDetail",
  data() {
    return {
      vipIcon: require("@/assets/icon/vip.png"),
      jobInfo: {},
      userInfo: {},
      comName: "",
      starIcon: "el-icon-star-off",
      starText: "收藏",
      starWorkId: "",
      partData: {},
    };
  },
  computed: {},
  methods: {
    getInfo() {
      let workId = this.$route.query.id;
      this.$http
        .post("index/home/getqualifications", { id: workId })
        .then((res) => {
          if (res.data.resultCode === 1) {
            this.partData = res.data.resultData.list[0];
          }
        });
    },
  },
  mounted() {
    this.getInfo();
  },
};
</script>
<style scoped>
.contentBox {
  padding-top: 0;
}
.infoBoxBg {
  height: 200px;
  background: url(../../assets/img/infoBoxBg.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
}
.colorFFF p {
  color: #fff;
}
.header .title {
  padding: 30px 0;
  font-size: 32px;
}
.header .line {
  height: 1px;
  background: #ffffff;
  margin: 10px 0;
}
.header .time span {
  margin-left: 24px;
}
.header .bottom span {
  margin-left: 24px;
}
/* .comInfoBox {
  display: inline-block;
  box-sizing: border-box;
  height: 60px;
  position: relative;
  top: -11px;
  padding-left: 12px;
}
.comInfoBox p:first-child {
  padding: 10px 0 5px;
}
.line-title {
  font-size: 17px;
  font-weight: bold;
}
.line-title span {
  color: #2674fc;
  padding-right: 12px;
}
.workInfoBox {
  padding-bottom: 20px;
  padding-top: 6px;
  text-indent: 16px;
}
.left-infoBox {
  background-color: #fff;
  border-radius: 4px;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 0px 20px;
}
.comBox {
  padding-top: 20px;
  padding-bottom: 10px;
}
.comInfoBox .infoShow > span:not(:last-child) {
  padding-right: 12px;
}
.comInfoBox .operation {
  float: right;
}
.comInfoBox .operation > span {
  margin-left: 16px;
}
.comInfoBox .operation > span i {
  font-size: 16px;
}
.operation > span:hover {
  cursor: pointer;
  color: #2573f1;
  box-shadow: 0px 1px 3px #ddd;
}
.jobInfoShow,
.jobInfoOperation {
  padding: 44px 0;
}
.jobInfoShow p {
  color: #fff;
  padding-bottom: 15px;
}
.jobInfoShow p:first-child {
  font-size: 22px;
  font-weight: bold;
}
.jobInfoShow p:first-child span:not(:last-child) {
  padding-right: 24px;
}
.worktypeBtn {
  background-color: #f7b500;
  border-radius: 4px;
  padding: 6px 10px;
  font-size: 14px;
  font-weight: 200;
}
.jobInfoShow p:nth-child(2) span:not(:first-child),
.jobInfoShow p:nth-child(3) span:not(:first-child) {
  padding-left: 10px;
}
.jobInfoShow p:nth-child(2) span:not(:last-child),
.jobInfoShow p:nth-child(3) span:not(:last-child) {
  padding-right: 10px;
  border-right: 1px solid #fff;
}
.el-icon-position {
  transform: rotateY(180deg);
} */
</style>
